import { Fragment, useEffect, useState } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { changeDate } from "utils/changeDate";
import { addDays } from "date-fns";
import { useData } from "data/data-provider";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import { getUnavailableVehicleData } from "api/vehicles/vehicle";
import toast from "react-hot-toast";
import DatePickerCustomHeaderOneMonth from "components/DatePickerCustomHeaderOneMonth";

export interface RentalCarDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  selectedCar?: any;
  fleetId?: number;
  isMiddle?: boolean;
  searchBar?: boolean;
}

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  selectedCar,
  fleetId,
  isMiddle,
  searchBar,
}) => {
  const {
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    endPeriod,
    ourVehicles,
    setStartDate,
    setEndDate,
    setUnavailable,
  } = useData();

  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [endDatePayload, setEndDatePayload] = useState<Date>(new Date());
  const [startDatePayload, setStartDatePayload] = useState<Date>(new Date());

  const getLastDateOfNextMonth = (currentDate: Date): Date => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 3,
      0
    );
    return nextMonth;
  };
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const monthsToShow = isLargeScreen ? 2 : 1;

  const fetchUnavailableDates = async (startDate: Date, endDate: Date) => {
    if (selectedCar?.id !== undefined) {
      try {
        const response = await getUnavailableVehicleData(
          {
            start_date: startDate.toISOString().split("T")[0],
            end_date: endDate.toISOString().split("T")[0],
          },
          selectedCar?.id
        );

        if (response.data) {
          const unavailable = response.data
            .filter((item: { available: boolean }) => !item.available)
            .map((item: { date: string }) => new Date(item.date));
          setUnavailableDates(unavailable);
        }
      } catch (error) {
        console.error("Error fetching unavailable dates:", error);
      }
    } else {
      console.warn("fleetId is undefined, unable to fetch unavailable dates.");
    }
  };

  const checkForUnavailableDatesInRange = (
    dates: [Date | null, Date | null]
  ) => {
    const selectedDates = dates.filter((date) => date !== null) as Date[];

    if (selectedDates.length < 2) {
      return false;
    }

    const [start, end] = selectedDates;
    const dateRange = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange.some((date) =>
      unavailableDates.some(
        (unavailableDate) =>
          unavailableDate.toDateString() === date.toDateString()
      )
    );
  };

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const hasUnavailableDates = checkForUnavailableDatesInRange(dates);
    if (selectedCar?.id) {
      if (hasUnavailableDates) {
        toast.error("This car is not available in the selected date range");
        setUnavailable(true);
      } else {
        setUnavailable(false);
      }
    }
    changeDate(
      dates,
      startHours,
      startMinutes,
      startPeriod,
      endHours,
      endMinutes,
      endPeriod,
      setStartDate,
      setEndDate
    );
  };

  useEffect(() => {
    const today = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(today.getMonth() + 3);
    setStartDatePayload(today);
    setEndDatePayload(threeMonthsLater);
    fetchUnavailableDates(today, threeMonthsLater);
  }, []);

  const handleMonthChange = (date: Date) => {
    const today = new Date();
    const newEndDate = new Date(date);
    newEndDate.setMonth(newEndDate.getMonth() + 3);
    setStartDatePayload(date);
    setEndDatePayload(newEndDate);
    fetchUnavailableDates(today, newEndDate);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span
            className={`block xl:text-lg font-semibold ${
              searchBar === false ? "text-black" : "text-white"
            }`}
          >
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span
            className={`block mt-1 text-sm ${
              searchBar === false ? "text-black" : "text-white"
            } leading-none font-light`}
          >
            {"Pickup & Drop off Date"}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <Popover
        className={`RentalCarDatesRangeInput z-20 relative flex ${className}`}
      >
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused !rounded-xl" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 z-10  flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}

                {startDate && open && (
                  <ClearDataButton
                    onClick={() => {
                      const today = new Date();
                      const endDate = new Date(today);
                      endDate.setDate(today.getDate() + 2);
                      onChangeDate([today, endDate]);
                    }}
                  />
                )}
              </Popover.Button>
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 dark:bg-neutral-800"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute left-1/2 z-40 mt-3 top-full w-screen max-w-2xl -translate-x-1/2 ${
                  isMiddle ? "lg:-translate-x-1/3" : "-translate-x-1/2"
                } xl:-translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl`}
              >
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-[#FFFFFF] dark:bg-neutral-800 p-8">
                  <DatePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    monthsShown={monthsToShow}
                    showPopperArrow={false}
                    inline
                    minDate={new Date()}
                    filterDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);

                      // Check if the date is unavailable
                      const isUnavailable = unavailableDates.some(
                        (unavailableDate) =>
                          unavailableDate.toDateString() === date.toDateString()
                      );

                      // Always disable unavailable dates
                      if (isUnavailable) {
                        return false;
                      }

                      return date >= today;
                    }}
                    renderCustomHeader={(p) => (
                      <>
                        <div className="block sm:hidden">
                          <DatePickerCustomHeaderOneMonth {...p} />
                        </div>
                        <div className="hidden sm:block">
                          <DatePickerCustomHeaderTwoMonth {...p} />
                        </div>
                      </>
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay
                        dayOfMonth={day}
                        date={date}
                        unavailableDates={unavailableDates}
                      />
                    )}
                    onMonthChange={handleMonthChange}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default RentalCarDatesRangeInput;
